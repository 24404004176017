import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import get from 'lodash.get';
import Radio from './Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from 'clsx';
import {RadioGroupProps} from '@material-ui/core/RadioGroup/RadioGroup';
import {FormControlProps} from '@material-ui/core/FormControl/FormControl';
import {FormControlLabelProps} from '@material-ui/core/FormControlLabel/FormControlLabel';

const useStyles = makeStyles(theme => ({
  root: {
    '& .form-field-row': {
      flex: '1 0 100%',
      marginRight: 0,
      marginLeft: -7,
      '& .MuiRadio-root': {
        padding: 7,
        marginRight: 5,
      },
    },
  },
  legend: {
    color: theme.palette.secondary.light,
    fontSize: 12,
    marginBottom: 2,
    '&.Mui-focused': {
      color: theme.palette.secondary.light,
    },
    '&.Mui-error': {
      color: theme.palette.secondary.light,
    },
  },
  control: {
    '&:not(.reset-spacing)': {
      marginRight: 26,
    },
    '& .MuiButtonBase-root': {
      padding: theme.spacing(1),
    },
  },
  label: {
    fontSize: 14,
    '&:not(.reset-spacing)': {
      minWidth: 50,
    },
  },
}));

interface CustomRadioGroupProps {
  legend?: string;
  label?: string;
  items: {label: string; value: string}[];
  radioGroupProps?: RadioGroupProps;
  formControlProps?: Omit<FormControlProps, 'ref'>;
  infoText?: ReactNode;
  name: string;
  radioProps?: FormControlLabelProps;
}

const CustomRadioGroup = (props: CustomRadioGroupProps) => {
  const {
    legend,
    label,
    name,
    items,
    infoText,
    formControlProps,
    radioGroupProps,
    radioProps,
  } = props;
  const classes = useStyles();

  return (
    <FormControl className={classes.root} fullWidth {...formControlProps}>
      <FormLabel className={classes.legend} component="legend">
        {legend ?? label}
      </FormLabel>
      <RadioGroup
        row
        defaultValue={get(items, '[0].value', '')}
        {...radioGroupProps}
      >
        {items.map(({label, value}, key) => (
          <FormControlLabel
            {...radioProps}
            key={key}
            classes={{
              root: clsx(classes.control, get(radioProps, 'classes.root')),
              label: clsx(classes.label, get(radioProps, 'classes.label')),
            }}
            value={value.toString()}
            control={(<Radio />) as FormControlLabelProps['control']}
            label={label}
            name={name}
          />
        ))}
      </RadioGroup>
      {infoText}
    </FormControl>
  );
};

export default CustomRadioGroup;
