import moment from 'moment';
import visa from '../assets/images/payment/visa.svg';
import amex from '../assets/images/payment/amex.svg';
import mastercard from '../assets/images/payment/mastercard.svg';
import cb from '../assets/images/payment/cb.png';
import {TRANSACTIONS_TYPE} from '../constants/transactions';
import { get, isEqual } from 'lodash';

/**
 * Flatted messages for react-intl
 * input {"a":{"b":"c"}}
 * output {"a.b":"c"}
 * @param {*} nestedMessages
 * @param {*} prefix
 */
export const flattenMessages = (nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }

  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, {[prefixedKey]: value});
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export function flattenObjectKeys(values, maxDepth, currentDepth = 1) {
  if (typeof values === 'object' && !Array.isArray(values)) {
    const fieldNames = [];
    for (const fieldName in values) {
      const fieldError = values[fieldName];
      if (fieldError !== undefined) {
        if (
          typeof fieldError === 'object' &&
          !Array.isArray(fieldError) &&
          currentDepth < maxDepth
        ) {
          const fieldFieldNames = flattenObjectKeys(
            fieldError,
            maxDepth,
            currentDepth + 1
          );
          for (const j in fieldFieldNames) {
            fieldNames.push([fieldName, fieldFieldNames[j]].join('.'));
          }
        } else {
          fieldNames.push(fieldName);
        }
      }
    }
    return fieldNames;
  }
  return [];
}

//Create an array of available hours using moment.js
export const timeOneDay = () => {
  //number of hours in the working day
  const hoursPerDay = 16;
  //result
  const time = [];
  //time object
  const startTime = moment();
  startTime.set({hours: 4, minutes: 0, seconds: 0});
  //fill in all of the hours
  for (let i = 0; i < hoursPerDay; i++) {
    const formattedTime = startTime.add(1, 'hours').format('h A'); //give the time in format X AM/PM
    time.push(formattedTime); //add to the array
  }
  return time;
};

/**
 * Transform a date string to the moment DD MMM Y form
 * to this format for example: 09 dec 2020
 *
 * @param dateString
 * @param dateFormat
 * @returns {string}
 */
export function formatDate(dateString, dateFormat = 'DD MMM Y') {
  const date = moment(dateString);
  return date.format(dateFormat);
}

export function fullName(user = null) {
  if (user.fullName) {
    return user.fullName;
  }
  const fields = ['firstName', 'lastName'];
  const parts = [];
  for (const field of fields) {
    if (user[field]) {
      parts.push(user[field]);
    }
  }
  return parts.length > 0 ? parts.join(' ') : user.email;
}

/**
 * Get resource identifier
 *
 * @param {string|Object} resource
 * @return {string|number|null}
 */
export function getResourceId(resource) {
  if (
    !resource ||
    (typeof resource === 'object' &&
      !resource['@id'] &&
      !resource['id'] &&
      !resource['_id'])
  ) {
    return null;
  }

  if (typeof resource === 'string' || typeof resource === 'number') {
    return resource;
  }

  if (resource._id || resource.id) {
    return resource._id || resource.id;
  }

  const parts = resource['@id']
    ? resource['@id'].split('/')
    : resource.split('/');
  for (const item of parts.reverse()) {
    if (/^\d+$/.test(item)) {
      return +item;
    }
  }

  return null;
}

/**
 * Scroll window to top
 */
export function scrollToTop() {
  try {
    window.history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

const numberFormat = (number, decimals, decPoint, thousandsSep) => {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? ' ' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
  let s = '';

  const toFixedFix = function (n, prec) {
    const k = Math.pow(10, prec);
    return '' + (Math.round(n * k) / k).toFixed(prec);
  };

  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  return s.join(dec);
};

export function fileSize(size, separator = '') {
  const units = ['b', 'kb', 'mb', 'gb'];
  let index = 0;

  while (size > 1024) {
    size = size / 1024;
    index++;
  }

  return numberFormat(size, index < 2 ? 0 : 1) + separator + units[index];
}

//nl2br from PHP function
export function nl2br(str, is_xhtml) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag =
    is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  );
}

/**
 * This will return a formatted money value
 * Ex: 12,345.67
 * @param input
 * @returns {*}
 */
export const formatMoney = input => {
  if (!input) return '';
  return input
    .replace(/,/g, '')
    .replace(/[^0-9.]/g, '')
    .replace('.', 'x')
    .replace(/\./g, '')
    .replace('x', '.')
    .replace(/^(\d+.?\d{0,2})\d*$/, '$1')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * This is useful to normalize the formatted money value
 * Ex: 12,345.67 -> 12345.67
 * @param val
 * @returns {*}
 */
export const normalizeMoneyFormat = val => {
  return val.replace(/,/g, '');
};

/**
 * This will return a label in string like {card icon} **** 1234 28/2022
 * @param props
 * @returns {string}
 */
export function cardLabel(props) {
  const {paymentMethod, lastNumbers = '', valid = ''} = props;

  // Define the payment as visa
  let paymentIcon = visa;

  if (paymentMethod === 'mastercard') {
    paymentIcon = mastercard;
  } else if (paymentMethod === 'amex') {
    paymentIcon = amex;
  } else if (paymentMethod === 'cb') {
    paymentIcon = cb;
  }

  return `<div class="d-flex align-items-center"><img src='${paymentIcon}' alt="${paymentMethod}" /><span class="ms-1">**** ${lastNumbers}</span><span class="ms-2">${valid}</span></div>`;
}

/**
 * Get Payment Status (color and label) and Type
 * @param payment
 * @param formatMessage
 * @returns {{color: *, label: *}|{}}
 */
export function getPaymentStatusAndType(payment, formatMessage) {
  if (!payment || !formatMessage) {
    return {};
  }

  //Use gray color as default color if status does not have yet a mapped color
  const defaultColor = '#9AA5B1';

  const transactionColor = {
    succeeded: '#21B59B',
    canceled: '#9AA5B1',
    expired: '#EE2737',
    requires_capture: '#FF8145',
    pending: '#FF8145',
    refunded: '#9AA5B1',
    paid: '#21B59B',
    'partially-refunded': '#FF8145',
    failed: '#EE2737',
  };

  const buyerRefundColor = {
    pending: '#FF8145',
    failed: '#EE2737',
    paid: '#21B59B',
    'partially-paid': '#21B59B',
  };

  const salePenaltyColor = {
    pending: '#FF8145',
    failed: '#EE2737',
    'partially-paid': '#21B59B',
    paid: '#21B59B',
  };

  const sellerChargeColor = {
    pending: '#9AA5B1',
    failed: '#EE2737',
    paid: '#21B59B',
    refunded: '#FF8145',
    'partially-refunded': '#FF8145',
  };

  const platformRefundColor = {
    pending: '#FF8145',
    failed: '#EE2737',
    paid: '#21B59B',
    'partially-paid': '#21B59B',
    refunded: '#FF8145',
    'partially-refunded': '#FF8145',
  };

  let color = defaultColor;
  let label = '';
  const type = payment['@type'];
  const status = payment['status']
    ? payment['status'].toLowerCase()
    : 'pending';
  const typeLabel = TRANSACTIONS_TYPE[type] || 'Payment';

  switch (type) {
    case 'Transaction':
      color = transactionColor[status];
      label = formatMessage({id: `transaction_status.${status}`});
      break;
    case 'PlatformRefund':
      color = platformRefundColor[status];
      label = formatMessage({id: `platform_refund_status.${status}`});
      break;
    case 'SellerCharge':
      color = sellerChargeColor[status];
      label = formatMessage({id: `seller_charge_status.${status}`});
      break;
    case 'SalePenalty':
      color = salePenaltyColor[status];
      label = formatMessage({id: `sale_penalty_status.${status}`});
      break;
    case 'BuyerRefund':
      color = buyerRefundColor[status];
      label = formatMessage({id: `buyer_refund_status.${status}`});
      break;
    default:
      label = status;
      color = defaultColor;
  }

  return {
    type: typeLabel,
    status: {color, label},
  };
}

/**
 * Format currency value
 *
 * @param amount
 * @returns {*}
 */
export function formatCurrencyValue(amount) {
  if (!amount) {
    return amount;
  }
  if (typeof amount !== 'number') {
    amount = Number(amount);
  }

  //use "," as thousand separator and "." as decimal separator
  const numberFormatter = Intl.NumberFormat('en-GB');
  return numberFormatter.format(amount.toFixed(2));
}

//Test if variable is empty
export function isEmpty(value) {
  if (typeof value === 'undefined' || value === null) {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }
  return typeof value === 'string' && value.trim() === '';
}

/**
 * Test if card is expired
 * @param card
 */
export function cardIsExpired(card) {
  if (!card || !card.expMonth || !card.expYear) {
    return true;
  }
  const endOfMonth = moment().endOf('month');
  const expirationDate = moment(
    `${card.expYear}-${card.expMonth.toString().padStart(2, '0')}`
  ).endOf('month');

  return expirationDate.isBefore(endOfMonth);
}

export function countDifferences(obj1: unknown, obj2: unknown): number {
  const emptyValues = [null, undefined];

  if (emptyValues.includes(obj1) && emptyValues.includes(obj2)) {
    return 0;
  } else if (emptyValues.includes(obj1) || emptyValues.includes(obj2)) {
    return 1;
  }

  if (typeof obj1 !== 'object') {
    return !isEqual(obj1, obj2) ? 1 : 0;
  }

  if (Array.isArray(obj1)) {
    return obj1.reduce((res, item, i) => res + countDifferences(item, get(obj2, i, undefined)), 0);
  }

  return Object.keys(obj1).reduce((res, field) => res + countDifferences(obj1[field], get(obj2, field)), 0);
}
